<template>
	<ion-page>
		<!-- Title toolbar disamping icon hamburger menu -->
		<ion-header :translucent="true">
			<ion-toolbar color="ari" class="ion-text-center">
				<ion-title>Profile Akun</ion-title>
			</ion-toolbar>
		</ion-header>

		<ion-content :fullscreen="true" class="ion-padding">
			<div>
				<div class="avatar">
					<img
						v-if="d.doctorPhotoUrl != ''"
						:src="d.doctorPhotoUrl"
						alt="photo-profile"
						class="imgProfile"
					/>
					<img
						class="imgProfile"
						v-else
						src="../assets/images/avatar.svg"
						alt="profile img"
					/>
				</div>
				<div>
					<div class="toggle">
						<ion-item lines="none">
							<ion-toggle
								color="ari"
								v-model="d.toggle"
								:checked="d.toggle"
								@click="updateStatus()"
							></ion-toggle
							>{{ d.toggle ? "&nbsp;Online" : "&nbsp;Offline" }}
						</ion-item>
					</div>
					<ion-item lines="none" style="text-align:center;">
						<ion-label
							><b>{{ d.doctorName }}</b></ion-label
						>
					</ion-item>

					<ion-item lines="none">
						<!-- <ion-icon :icon="fitnessOutline" slot="start"></ion-icon> -->
						<img
							src="@/assets/relidoc/spesialis.svg"
							alt=""
							class="icon-width"
						/>
						<span class="text-icon"
							>Spesialis: {{ d.doctorCategory || "-" }}
						</span>
						<!-- <ion-label>Spesialis: {{ d.doctorCategory || "-" }}</ion-label> -->
					</ion-item>

					<ion-item lines="none">
						<!-- <ion-icon :icon="calendarOutline" slot="start"></ion-icon>
						<ion-label>Tahun : {{ d.doctorYear || "-" }}</ion-label> -->
						<img
							src="@/assets/relidoc/pengalaman.svg"
							alt=""
							class="icon-width"
						/>
						<span class="text-icon">Tahun : {{ d.doctorYear || "-" }}</span>
					</ion-item>

					<ion-item lines="none">
						<!-- <ion-icon :icon="callOutline" slot="start"></ion-icon>
						<ion-label>Telp : {{ d.doctorPhoneNo || "-" }}</ion-label> -->
						<img
							src="@/assets/relidoc/telephone.svg"
							alt=""
							class="icon-width"
						/>
						<span class="text-icon">Telp : {{ d.doctorPhoneNo || "-" }}</span>
					</ion-item>
					<ion-item lines="none">
						<!-- <ion-icon :icon="logOutOutline" slot="start"></ion-icon>
						<ion-label @click="logout()" class="logout" color="ari"
							>Logout Aplikasi</ion-label
						> -->
						<img
							@click="logout()"
							src="@/assets/relidoc/logout.svg"
							alt=""
							class="icon-width"
						/>
						<span @click="logout()" class="text-icon logout"
							>Logout Aplikasi</span
						>
					</ion-item>
				</div>
			</div>
			<ion-toast
				:is-open="isOpenRef"
				:message="toastMessage"
				:duration="4000"
				@didDismiss="setOpen(false)"
			>
			</ion-toast>
		</ion-content>

		<bottom-tab-bar></bottom-tab-bar>
	</ion-page>
</template>

<script>
import {
	IonToggle,
	IonContent,
	IonHeader,
	IonPage,
	IonTitle,
	IonToolbar,
	IonItem,
	IonLabel,
	// IonIcon,
	IonToast,
} from "@ionic/vue";

import {
	fitnessOutline,
	calendarOutline,
	callOutline,
	logOutOutline,
} from "ionicons/icons";
import { reactive, inject, ref } from "vue";
import { useRouter } from "vue-router";
import BottomTabBar from "@/components/BottomTabBar.vue";
//import DoctorEndChat from "@/components/DoctorEndChat.vue";
export default {
	name: "TicketInformation",
	components: {
		IonToggle,
		IonContent,
		IonHeader,
		IonPage,
		IonTitle,
		IonToolbar,
		IonItem,
		IonLabel,
		// IonIcon,
		IonToast,
		BottomTabBar,
	},
	data() {
		return {
			isOpen: false,
		};
	},
	setup() {
		const store = inject("store");
		const router = useRouter();
		const isOpenRef = ref(false);
		const toastMessage = ref(null);
		const setOpen = (state) => (isOpenRef.value = state);

		const d = reactive({
			doctorName: localStorage.getItem("DoctorName"),
			doctorCategory: localStorage.getItem("DoctorCategory"),
			doctorPhotoUrl: localStorage.getItem("DoctorPhotoUrl"),
			doctorYear: localStorage.getItem("DoctorYear"),
			doctorPhoneNo: localStorage.getItem("DoctorPhoneNo"),
			toggle: localStorage.getItem("DoctorIsOnline") || null,
		});

		if (d.toggle == "true") {
			d.toggle = true;
		}
		if (d.toggle == "false") {
			d.toggle = false;
		}
		//Update status
		async function updateStatus() {
			const status = !d.toggle ? "ONLINE" : "OFFLINE";
			const r = await store.userVerification.updateStatus(status);

			//set toast notification
			if (r.data.ResponseCode == "100") {
				toastMessage.value = r.data.ResponseMessage;
				d.toggle = r.data.doctor_isonline;
				isOpenRef.value = true;
				localStorage.setItem("DoctorIsOnline", r.data.doctor_isonline);
			}
			//check is success update
			if (r.status === 200 && r.data.ResponseCode == "000") {
				localStorage.setItem("DoctorIsOnline", r.data.doctor_isonline);
				d.toggle = r.data.doctor_isonline;
			}
		}

		//logout
		function logout() {
			store.userVerification.logout();
			setTimeout(() => {
				router.replace("/login");
				location.reload();
			}, 200);
		}

		return {
			d,
			fitnessOutline,
			calendarOutline,
			callOutline,
			logOutOutline,
			updateStatus,
			logout,
			setOpen,
			isOpenRef,
			toastMessage,
		};
	},
	methods: {
		IsOpenTrue() {
			this.isOpen = true;
		},
		IsOpenFalse(v) {
			this.isOpen = v;
		},
	},
};
</script>

<style scoped>
.icon-width {
	width: 25px;
	margin-right: 20px;
	cursor: pointer;
}
.text-icon {
	font-size: 16px;
	cursor: pointer;
}
ion-item {
	cursor: pointer;
}
.bg-information {
	background-color: red;
}
.personIcon {
	color: #1a2674;
	margin-right: 10px;
	font-size: 40px;
}
ion-card,
ion-item {
	cursor: default;
}
ion-card {
	margin: 0 auto;
	padding: 20px;
	padding-bottom: 60px;
	max-width: 370px;
}
.avatar {
	margin: 0 auto;
	margin-top: 10px;
	margin-bottom: 10px;
	text-align: center;
}
.imgProfile {
	width: 150px;
	height: 150px;
	border-radius: 50%;
	border: 1px solid #cacaca;
	object-fit: cover;
	margin: 0 auto;
}
.toggle {
	margin: 0 auto;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.logout {
	font-weight: bold;
	font-size: 14px;
	cursor: pointer;
}
</style>
